import React, { useState, useEffect } from "react";
import Door from "./Door";
import { videoLinks } from "./videoLinks";

const COOKIE_NAME = "openedDoors";

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()!.split(";").shift();
};

const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

const AdventCalendar: React.FC = () => {
  const daysInDecember = 24;
  const [openedDoors, setOpenedDoors] = useState<number[]>([]);
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);

  // Check query parameters for testing purposes
  const queryParams = new URLSearchParams(window.location.search);
  const mockDay = queryParams.has("mockDay")
    ? parseInt(queryParams.get("mockDay")!, 10)
    : null;
  const mockMonth = mockDay
    ? 12
    : queryParams.has("mockMonth")
    ? parseInt(queryParams.get("mockMonth")!, 10)
    : null;

  // Determine the current day and month, using mock values if present
  const today = new Date();
  const currentDay = mockDay ?? today.getDate();
  const currentMonth = mockMonth ?? today.getMonth() + 1;

  // Load previously opened doors from cookie on initial load
  useEffect(() => {
    const cookieData = getCookie(COOKIE_NAME);
    if (cookieData) {
      const doorsFromCookie = cookieData.split(",").map(Number);
      setOpenedDoors(doorsFromCookie);
    }
  }, []);

  const handleDoorClick = (day: number) => {
    const referrer = document.referrer;

    // Replace 'yourcalendarwebsite.com' with your actual website URL
    if (
      referrer.includes("yourcalendarwebsite.com") ||
      window.location.hostname === "localhost" ||
      window.location.hostname === "blobbybeadsadventxmas.mwmbwls.co.uk" ||
      window.location.hostname === "d3fd1nw5wr0t3q.cloudfront.net"
    ) {
      const canOpen = currentMonth === 12 && currentDay >= day;

      // Check if all previous doors have been opened
      const allPreviousDoorsOpened = Array.from(
        { length: day - 1 },
        (_, i) => i + 1
      ).every((d) => openedDoors.includes(d));

      if (canOpen && allPreviousDoorsOpened) {
        setOpenedDoors((prevOpenedDoors) => {
          const newOpenedDoors = [...prevOpenedDoors, day];
          setCookie(COOKIE_NAME, newOpenedDoors.join(","), 30); // Store in cookie for 30 days
          return newOpenedDoors;
        });
        setCurrentVideo(videoLinks[day - 1]);
      } else if (!allPreviousDoorsOpened) {
        alert(`Please open Day ${day - 1} before opening Day ${day}.`);
      } else {
        alert(`You can't open this door until December ${day}.`);
      }
    } else {
      alert("Please access this video through the official calendar website.");
    }
  };

  const closeModal = () => {
    setCurrentVideo(null);
  };

  return (
    <div className="calendar-grid">
      {Array.from({ length: daysInDecember }, (_, i) => {
        const day = i + 1;
        const isOpen = openedDoors.includes(day);

        // Check if the door can be opened based on date and previous doors
        const canOpen = currentMonth === 12 && currentDay >= day;
        const isDisabled =
          canOpen &&
          !Array.from({ length: day - 1 }, (_, j) => j + 1).every((d) =>
            openedDoors.includes(d)
          );

        return (
          <Door
            key={day}
            day={day}
            isOpen={isOpen}
            canOpen={!isDisabled && canOpen}
            onClick={() => handleDoorClick(day)}
            className={isDisabled ? "door-disabled" : ""}
          />
        );
      })}
      {currentVideo && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <iframe
              src={currentVideo}
              title="Video"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <button className="modal-close" onClick={closeModal}>
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdventCalendar;
