// src/components/videoLinks.ts

// src/components/videoLinks.ts

export const videoLinks: string[] = [
  "https://d1pdjttv6cphmb.cloudfront.net/day-01.mp4", // Day 1
  "https://d1pdjttv6cphmb.cloudfront.net/day-02.mp4", // Day 2
  "https://d1pdjttv6cphmb.cloudfront.net/day-03.mp4", // Day 3
  "https://d1pdjttv6cphmb.cloudfront.net/day-04.mp4", // Day 4
  "https://d1pdjttv6cphmb.cloudfront.net/day-05.mp4", // Day 5
  "https://d1pdjttv6cphmb.cloudfront.net/day-06.mp4", // Day 6
  "https://d1pdjttv6cphmb.cloudfront.net/day-07.mp4", // Day 7
  "https://d1pdjttv6cphmb.cloudfront.net/day-08.mp4", // Day 8
  "https://d1pdjttv6cphmb.cloudfront.net/day-09.mp4", // Day 9
  "https://d1pdjttv6cphmb.cloudfront.net/day-10.mp4", // Day 10
  "https://d1pdjttv6cphmb.cloudfront.net/day-11.mp4", // Day 11
  "https://d1pdjttv6cphmb.cloudfront.net/day-12.mp4", // Day 12
  "https://d1pdjttv6cphmb.cloudfront.net/day-13.mp4", // Day 13
  "https://d1pdjttv6cphmb.cloudfront.net/day-14.mp4", // Day 14
  "https://d1pdjttv6cphmb.cloudfront.net/day-15.mp4", // Day 15
  "https://d1pdjttv6cphmb.cloudfront.net/day-16.mp4", // Day 16
  "https://d1pdjttv6cphmb.cloudfront.net/day-17.mp4", // Day 17
  "https://d1pdjttv6cphmb.cloudfront.net/day-18.mp4", // Day 18
  "https://d1pdjttv6cphmb.cloudfront.net/day-19.mp4", // Day 19
  "https://d1pdjttv6cphmb.cloudfront.net/day-20.mp4", // Day 20
  "https://d1pdjttv6cphmb.cloudfront.net/day-21.mp4", // Day 21
  "https://d1pdjttv6cphmb.cloudfront.net/day-22.mp4", // Day 22
  "https://d1pdjttv6cphmb.cloudfront.net/day-23.mp4", // Day 23
  "https://d1pdjttv6cphmb.cloudfront.net/day-24.mp4", // Day 24
];
