// src/components/Door.tsx

import React from "react";

interface DoorProps {
  day: number;
  isOpen: boolean;
  canOpen: boolean;
  onClick: () => void;
  className?: string;
}

const Door: React.FC<DoorProps> = ({
  day,
  isOpen,
  canOpen,
  onClick,
  className,
}) => {
  return (
    <div
      className={`door ${isOpen ? "open" : ""} ${
        !canOpen ? "door-locked" : ""
      } ${className || ""}`}
      onClick={canOpen ? onClick : undefined} // Only allow click if canOpen is true
    >
      {isOpen ? (
        <p>{day}</p>
      ) : (
        <div className="door-content">
          <p>{day}</p>
          {!canOpen && <span className="lock-icon">🔒</span>}{" "}
          {/* Display lock if door is locked */}
        </div>
      )}
    </div>
  );
};

export default Door;
