// src/App.tsx

import React from "react";
import "./App.css";
import AdventCalendar from "./components/AdventCalendar";

const App: React.FC = () => {
  return (
    <div className="App">
      <h1>
        Advent Calendar<span className="subtext">by @blobbybeads</span>
      </h1>
      <AdventCalendar />
    </div>
  );
};

export default App;
